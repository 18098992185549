import { Ionicons } from '@expo/vector-icons'
import React, { useState, useEffect } from 'react'
import { FlatList, Platform, ScrollView, Text, View, useWindowDimensions } from 'react-native'
import DraggableFlatList, { ScaleDecorator } from 'react-native-draggable-flatlist'

import {
	ListItem,
	ThemeProvider,
	theme
} from "react-native-design-system"
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { colors } from '../../../Helpers/variables'
import Track from './Track'
import { trackStyles } from './TrackStyles'
import PlaylistDetails from './PlaylistDetails'
import { tracklistSortByName, tracklistSortByPosition } from '../../../Helpers/sortArray'
import { Filter } from '../../../Components/Forms/Filter'

export default function TrackList({
	tracksList,
	photo,
	title,
	isTracksRemovable,
	setTrackList,
	userPlaylists = [],
	isTrackPositionChangeable,
	navigation,
	updateUserPlaylists,
	openNotifModal,
	closeNotifModal,
	edit,
	setEdit,
	tracks,
	hasCopyPlaylistBtn,
	musicTherapistName,
	musicTherapistId,
	saveTracksPosition,
	copyMyPlaylistLabel,
	isRepeatAllTracks = false,
	isMultipleTrackList = false,
	isHidePlaylistDetails = true,
	...props
}) {

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const positionValueLabel = 'position'
	const alphabeticalValueLabel = 'alphabetical'
	const [sortedTrackslists, setSortedTrackslist] = useState(tracksList)

	const [sortValue, setSortValue] = useState('Skift')
	const sortByValues = [{
		value: positionValueLabel,
		label: 'Standard'
	}, {
		value: alphabeticalValueLabel,
		label: 'Alfabetisk'
	}]

	const onSortValueChange = (e) => {
		setSortValue(e)
		handleTracksSorting(e)
	}

	const TrackListView = () => (
		<ScrollView>
			<FlatList
				data={sortedTrackslists}
				renderItem={({ item, index }) => (
					<View style={trackStyles.trackList}>
						<Track
							edit={edit}
							track={item.track}
							tracksList={sortedTrackslists}
							playlistTrackId={item.id}
							playlistId={item.playlist}
							isTracksRemovable={isTracksRemovable}
							setTrackList={setTrackList}
							userPlaylists={userPlaylists}
							navigation={navigation}
							updateUserPlaylists={updateUserPlaylists}
							openNotifModal={openNotifModal}
							closeNotifModal={closeNotifModal}
							isRepeatAllTracks={isRepeatAllTracks}
							item={item}
							trackIndex={index}
						/>
					</View>
				)}
				keyExtractor={item => item.id + new Date()}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingBottom: 40 }}
				showsVerticalScrollIndicator={true}
				showsHorizontalScrollIndicator={false}
				ListHeaderComponent={Header}
				scrollEnabled={true}
			/>
		</ScrollView>
	)

	const Header = () => {
		if (isHidePlaylistDetails)
			return null

		return (
			<View style={{
				flex: 1,
			}}>
				<View>
					<PlaylistDetails
						photo={photo}
						title={title}
						tracks={sortedTrackslists.length}
						tracksList={sortedTrackslists}
						hasCopyPlaylistBtn={hasCopyPlaylistBtn}
						edit={edit}
						setEdit={setEdit}
						isTrackPositionChangeable={isTrackPositionChangeable}
						musicTherapistName={musicTherapistName}
						musicTherapistId={musicTherapistId}
						saveTracksPosition={saveTracksPosition}
						copyMyPlaylistLabel={copyMyPlaylistLabel}
						sortByValues={sortByValues}
						onSortValueChange={onSortValueChange}
						sortValue={sortValue}
					/>
				</View>
			</View>
		)
	}

	const handleMarkAsCompleted = (key) => {
		setTrackList((prevData) => {
			prevData.map((item) => {
				if (item.id === id) {
					item.isCompleted = !item.isCompleted;
				}
				return item;
			});
		});
	};

	const renderItem = ({ item, drag, isActive }) => {

		return (
			<ScaleDecorator>
				<ListItem
					size="lg"
					onLongPress={drag}
					disabled={isActive}
					background={isActive ? colors.accent : colors.primary}
					onPress={() => handleMarkAsCompleted(item.id)}
					leftIcon={
						<Ionicons name="move" size={26} color={'white'} style={{ marginHorizontal: 10 }} />
					}
					textStyle={{
						textDecorationLine: item.isCompleted ? "line-through" : "none",
					}}
					style={{ backgroundColor: isActive ? colors.accent : colors.primary }}
				>
					<View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
						<Text style={{ color: 'white' }}>{item.track.song_title}</Text>
					</View>
				</ListItem>
			</ScaleDecorator>
		)

	}

	const handleTracksSorting = (sortBy = positionValueLabel) => {
		let newSortedTrackslists = []
		let tempList = [...sortedTrackslists]

		if (sortBy === positionValueLabel)
			newSortedTrackslists = tracklistSortByPosition(tempList)
		else
			newSortedTrackslists = tracklistSortByName(tempList, false)

		setSortedTrackslist(newSortedTrackslists)
	}

	return !edit ? (
		<TrackListView />
	) : (
		<GestureHandlerRootView>
			<ThemeProvider theme={theme}>
				<DraggableFlatList
					// scrollEnabled={scrollEnabled}
					// onDragBegin={() => setScrollEnabled(false)}
					data={sortedTrackslists}
					onDragEnd={({ data, from, to }) => {
						setTrackList(data)
						setSortedTrackslist(data)
					}}
					keyExtractor={item => item.id}
					renderItem={renderItem}
					ListHeaderComponent={Header}
					containerStyle={{ height: Platform.OS === 'web' ? '83%' : '100%' }}
				// outerScrollOffset={scrollOffset}
				/>
			</ThemeProvider>
		</GestureHandlerRootView>
	)
}
