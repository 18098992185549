import React, { useState, useEffect } from 'react'
import { FlatList, Text, useWindowDimensions, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import useLayout from '../../Hooks/useLayout'
import { ResetButton } from '../Buttons/Button'
import { Heading } from '../Typography/Typography'
import { PlaylistCard } from './PlaylistCards'
import { Filter } from '../Forms/Filter'
import { playlistSortByName, playlistSortByPosition } from '../../Helpers/sortArray'

const PlaylistView = ({ playlists, wasDataCached, setShouldReset, handlePlaylistPress, hideResetBtn = false, categoryName }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const positionValueLabel = 'position'
	const alphabeticalValueLabel = 'alphabetical'
	const [sortValue, setSortValue] = useState('Skift')
	const sortByValues = [{
		value: positionValueLabel,
		label: 'Standard'
	}, {
		value: alphabeticalValueLabel,
		label: 'Alfabetisk'
	}]

	const [newPlaylist, setNewPlaylist] = useState([])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	useEffect(() => {
		handleResize()
	}, [width])

	const setPlaylists = (sortBy = positionValueLabel) => {
		let dividedItem = 1
		const maxItemsPerRow = 9
		let tempNewPlaylist = []
		let counter = 1
		let recordCtr = 0
		let sortedPlaylists = playlists

		if (sortedPlaylists.length > 0) {
			if (sortedPlaylists.filter(x => x.playlist_section_group_code !== '01').length > 0) {
				let sections = [
					...new Set(sortedPlaylists.map((element) => element.playlist_section_group_position)),
				];
				sections.map((item, index) => {
					tempNewPlaylist.push({
						counter: index,
						playlist: sortBy === positionValueLabel ?
							playlistSortByPosition(sortedPlaylists.filter(x => x.playlist_section_group_position === item)) :
							playlistSortByName(sortedPlaylists.filter(x => x.playlist_section_group_position === item), false),
						header: sortedPlaylists.filter(x => x.playlist_section_group_position === item).length > 0 ? sortedPlaylists.filter(x => x.playlist_section_group_position === item)[0].playlist_section_group_header : ''
					})
				})

			}
			else {

				if (sortBy === positionValueLabel)
					sortedPlaylists = playlistSortByPosition(sortedPlaylists)
				else
					sortedPlaylists = playlistSortByName(sortedPlaylists, false)

				if (sortedPlaylists.length > maxItemsPerRow)
					dividedItem = Math.ceil(sortedPlaylists.length / maxItemsPerRow)

				while (counter <= dividedItem) {
					let temp = []
					while ((recordCtr) < sortedPlaylists.length) {
						if (recordCtr >= maxItemsPerRow * counter)
							break;
						temp.push(sortedPlaylists[recordCtr])
						recordCtr++
					}

					tempNewPlaylist.push({
						counter: counter,
						playlist: temp
					})
					counter++
				}
			}
		}

		setNewPlaylist(tempNewPlaylist)

	}


	useEffect(() => {
		setPlaylists()
	}, [])

	const onSortValueChange = (e) => {
		setSortValue(e)
		setPlaylists(e)
	}

	return (
		<View style={{ flex: 1 }}>
			{!hideResetBtn && (
				<View style={{ padding: 10, marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
					<Heading style={{ color: colors.accent, fontSize: 22 }}> {categoryName} </Heading>
					<View style={{ flexDirection: 'row' }}>
						<Filter value={sortValue} items={sortByValues} onSortValueChange={onSortValueChange}
							defaultButtonText={sortValue} newItemLabelName={'Filter Feature'} />
						<ResetButton style={{ width: 100 }} onPress={() => setShouldReset(true)} />
					</View>
				</View>
			)}
			{
				newPlaylist.length === 0 &&
				<Text style={{ color: 'white', marginTop: 30, fontSize: 15, marginLeft: 10 }}>Ingen spillelister.</Text>
			}
			{newPlaylist.map((items, index) => (
				<View key={items.counter}>
					<View style={{ padding: isWideScreen ? 10 : 5, marginBottom: 5, width: '100%' }}>
						<Text style={{ color: 'lightgray', fontSize: isWideScreen ? 22 : (isSmallScreen ? 18 : 20), width: '99%' }}>{items.header !== "{empty}" ? items.header : ""}</Text>
					</View>
					<FlatList
						// itemDimension={206}
						data={items.playlist}
						renderItem={item => (
							<PlaylistCard
								wasDataCached={wasDataCached}
								item={item}
								width={isSmallScreen ? 100 : (isWideScreen ? 130 : 120)}
								handlePlaylistPress={handlePlaylistPress}
							/>
						)}
						ListEmptyComponent={
							<View>
								<Text style={{ color: 'white', marginTop: 30, fontSize: 15, marginLeft: 5 }}>Ingen spillelister.</Text>
							</View>
						}
						keyExtractor={item => item.id}
						//if the user pulls from the top of the screen, log out to the console:
						onRefresh={() => console.log('refreshing')}
						//if set to true, the UI will show a loading indicator and trigger data.
						refreshing={false}
						horizontal={true}
						contentContainerStyle={{ marginTop: 10 }}
					/>
				</View>
			))}
		</View>
	)
}

export default React.memo(PlaylistView)
