import { Ionicons } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'
import { FlatList, Pressable, ScrollView, Text, useWindowDimensions, View } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import { colors, COPYPLAYLIST_ASSIGNED_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import { trackStyles } from '../TracksScreen/components/TrackStyles'

import { PlaylistCard } from '../../Components/Playlists/PlaylistCards'

import { useIsFocused } from '@react-navigation/native'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { ConfirmationModal } from '../../Components/Modals/ConfirmationModal'
import { ImportFromThirdPartyModal } from '../../Components/Modals/ImportFromThirdPartyModal'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { RequestedSongs } from '../../Components/Modals/RequestedSongs'
import { Heading } from '../../Components/Typography/Typography'
import { playlistSortByName } from '../../Helpers/sortArray'
import useLayout from '../../Hooks/useLayout'
import AuthService from '../../Services/AuthService'
import { CreatePlaylistModal } from '../TracksScreen/components/CreatePlaylistModal'

export default function MyPlaylistScreen({ navigation }) {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	const [userPlaylists, setUserPlaylists] = useState([])
	const [assignedPlaylists, setAssignedPlaylists] = useState([])
	const [loading, setLoading] = useState(true)
	const { deviceType } = useLayout()
	const [modalVisible, setModalVisible] = useState(false)
	const [playlistInfo, setPlaylistInfo] = useState({})
	const [removePlaylistConfirmModal, setRemovePlaylistConfirmModal] = useState(false)
	const [itemPlaylist, setItemPlaylist] = useState(undefined)
	const [itemCopyPlaylistId, setItemCopyPlaylistId] = useState(undefined)
	const [removeCopyPlaylistConfirmModal, setRemoveCopyPlaylistConfirmModal] = useState(false)

	const api = useAxios()
	const [showImportThirdPartyModal, setShowImportThirdPartyModal] = useState(false)
	const [showRequestedSongsModal, setShowRequestedSongsModal] = useState(false)

	// check if screen is focused
	const isFocused = useIsFocused()
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	useEffect(() => {
		isFocused && refreshView()
	}, [isFocused])

	const updateUserPlaylists = (id, name, description, visibility, photo) => {
		let userPlaylistsTemp = userPlaylists.filter(x => x.id !== id)
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility, Photo: photo })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	const getUserPlaylist = async () => {
		const result = await api.get(USER_PLAYLIST_URL)
		if (result.status === 200) {
			let user = playlistSortByName(result.data)
			setUserPlaylists(user)
		} else {
			console.log(result.status + ' - ' + result.statusText)
			setUserPlaylists([])
		}
	}

	const getCopyPlaylist = async () => {
		const result = await api.get(COPYPLAYLIST_ASSIGNED_URL)
		if (result.status === 201) {
			let playlist = playlistSortByName(
				result.data.filter(x => x.playlist_name !== undefined),
				false
			)
			setAssignedPlaylists(playlist)
		} else {
			console.log(result.status + ' - ' + result.statusText)
			setAssignedPlaylists([])
		}
	}

	const refreshView = async () => {
		console.log('refresh')
		await getUserPlaylist()
		await getCopyPlaylist()
		setLoading(false)
	}

	const handlePlaylistPress = async (playlistId, photo, title, isTracksRemovable, isTrackPositionChangeable = false, isMyPlaylist = false) => {
		let user = await AuthService.getUser()
		let musicTherapistName = isMyPlaylist ? (JSON.parse(user)).username : undefined
		let copyMyPlaylistLabel = isMyPlaylist ? 'Lavet af Musicmind' : 'Kopieret Musicmind spilleliste'
		navigation.navigate('TracksScreen', { playlistId, photo, title, isTracksRemovable, isTrackPositionChangeable, musicTherapistName, copyMyPlaylistLabel })
	}

	const handleDeletePlaylist = () => {
		let item = itemPlaylist
		api
			.delete(USER_PLAYLIST_URL + '/' + item.id)
			.then(response => {
				if (response.status === 204) {
					let newUserPlaylist = userPlaylists
					newUserPlaylist = newUserPlaylist.filter(x => x.id !== item.id)
					newUserPlaylist = playlistSortByName(newUserPlaylist)
					setUserPlaylists(newUserPlaylist)
					openNotifModal('Slet ', 'Fjernet')
				} else {
					openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				}
			})
			.catch(err => {
				openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
				setRemovePlaylistConfirmModal(false)
			})
	}

	const handleDeleteCopyPlaylist = () => {
		api
			.delete(COPYPLAYLIST_ASSIGNED_URL + '/' + itemCopyPlaylistId)
			.then(response => {
				if (response.status === 204) {
					let newUserPlaylist = assignedPlaylists
					newUserPlaylist = newUserPlaylist.filter(x => x.id !== itemCopyPlaylistId)
					setAssignedPlaylists(newUserPlaylist)
					openNotifModal('Slet ', 'Fjernet')
				} else {
					openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				}
			})
			.catch(err => {
				openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
				setRemoveCopyPlaylistConfirmModal(false)
			})
	}

	const cancelCallbackRemovePlaylist = () => {
		setRemovePlaylistConfirmModal(false)
	}

	const cancelCallbackRemoveCopyPlaylist = () => {
		setRemoveCopyPlaylistConfirmModal(false)
	}

	// const setDeleteConfirmModal = playlistId => {
	// 	setItemCopyPlaylistId(playlistId)
	// 	setRemoveCopyPlaylistConfirmModal(true)
	// }

	const cancelCallbackThirdParty = () => {
		setShowImportThirdPartyModal(false)
	}

	const cancelCallbackRequestedSongs = () => {
		setShowRequestedSongsModal(false)
	}

	const handleRequestedSongsModal = () => {
		setShowRequestedSongsModal(true)
	}

	const handleImportPlaylistModal = async () => {
		setShowImportThirdPartyModal(true)
		setShowImportThirdPartyModal(true)
	}

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView style={{ paddingBottom: 90 }}>
				<View style={{
					flexDirection: isWideScreen ? 'row' : 'column',
					justifyContent: isWideScreen ? 'space-between' : 'center',
					alignItems: isWideScreen ? 'center' : 'flex-start',
					marginTop: isWideScreen ? 10 : 0,
					paddingRight: isWideScreen ? 5 : 0,
				}}
				>
					<View>
						<Heading style={{ padding: isWideScreen ? 10 : 0, color: colors.accent, marginLeft: isWideScreen ? 0 : 10 }}>Importeret Spilleliste</Heading>
					</View>
					<View
						style={{
							flexDirection: isWideScreen ? 'row' : 'column',
							justifyContent: isWideScreen ? 'flex-end' : 'center',
							width: isWideScreen ? '40%' : '100%'
						}}
					>
						<PrimaryButton style={{ width: isWideScreen ? '50%' : '100%', minHeight: 30, marginVertical: 5 }} title={'Se Forespurgte Sange'} onPress={handleRequestedSongsModal} />
						<PrimaryButton style={{ width: isWideScreen ? '50%' : '100%', minHeight: 30, marginVertical: 5 }} title={'Importer Spilleliste'} onPress={handleImportPlaylistModal} />
					</View>
				</View>

				<View style={{ padding: 10, marginBottom: 10 }}>
					<Heading style={{ color: colors.accent }}>Tildelte spillelister</Heading>
				</View>
				<View>
					<FlatList
						itemDimension={200}
						data={assignedPlaylists}
						renderItem={item => (
							<PlaylistCard
								key={item.id}
								item={item}
								width={isWideScreen ? 220 : 180}
								handlePlaylistPress={handlePlaylistPress}
								setRemoveCopyPlaylistConfirmModal={setRemoveCopyPlaylistConfirmModal}
								hasDeleteButton={true}
								setItemCopyPlaylistId={setItemCopyPlaylistId}
							/>
						)}
						ListEmptyComponent={
							<View>
								<Text style={{ color: 'white', marginTop: 30, fontSize: 15, marginLeft: 10 }}>Ingen spillelister.</Text>
							</View>
						}
						keyExtractor={item => item.id}
						//if the user pulls from the top of the screen, log out to the console:
						onRefresh={() => console.log('refreshing')}
						//if set to true, the UI will show a loading indicator and trigger data.
						refreshing={false}
						horizontal={true}
					/>
				</View>

				<View style={{ padding: 10, marginBottom: 10 }}>
					<Heading style={{ color: colors.accent }}>Mine spillelister</Heading>
				</View>

				<View style={trackStyles.trackList}>
					<FlatList
						data={userPlaylists}
						renderItem={playlist => (
							<View style={trackStyles.trackRow} key={playlist.item.id}>
								<Pressable
									onPress={() =>
										handlePlaylistPress(playlist.item.id, playlist.item.photo ? playlist.item.photo : playlist.item.Photo, playlist.item.name, true, true, true)
									}
									style={trackStyles.songContainer}
								>
									<View>
										<Text style={{ color: 'white', fontSize: 14 }}>{playlist.item.name}</Text>
										<Text style={{ color: 'white', fontSize: 10 }}>{playlist.item.description}</Text>
									</View>
								</Pressable>
								<View style={trackStyles.leftContainer}>
									<Pressable
										onPress={() => {
											setModalVisible(true)
											setPlaylistInfo(playlist.item)
										}}
										style={{ marginLeft: 10 }}
									>
										<Ionicons name="create" size={25} color={'white'} />
									</Pressable>
									<Pressable
										onPress={() => {
											setRemovePlaylistConfirmModal(true)
											setItemPlaylist(playlist.item)
										}}
										style={{ marginLeft: 10 }}
									>
										<Ionicons name="trash" size={25} color={'white'} />
									</Pressable>
								</View>
							</View>
						)}
						ListEmptyComponent={
							<View>
								<Text style={{ color: 'white', marginTop: 30, fontSize: 15, marginLeft: 5 }}>Ingen spillelister.</Text>
							</View>
						}
						keyExtractor={item => item.id}
						numColumns={1}
						contentContainerStyle={{ paddingBottom: 90 }}
						showsVerticalScrollIndicator={false}
						showsHorizontalScrollIndicator={false}
					/>
				</View>


				{/* <View> */}
				{modalVisible && (
					<CreatePlaylistModal
						modalVisible={modalVisible}
						setModalVisible={setModalVisible}
						playlistInfo={playlistInfo}
						processType={'update'}
						updateUserPlaylists={updateUserPlaylists}
					/>
				)}
				{/* </View> */}

				{removePlaylistConfirmModal && (
					// <View>
					<ConfirmationModal
						handleSubmit={handleDeletePlaylist}
						modalVisible={removePlaylistConfirmModal}
						cancelCallback={cancelCallbackRemovePlaylist}
						description={'Er du sikker pø, at du vil fjerne denne spilleliste?'}
					/>
					// </View>
				)}


				{removeCopyPlaylistConfirmModal && (
					// <View>
					<ConfirmationModal
						handleSubmit={handleDeleteCopyPlaylist}
						modalVisible={removeCopyPlaylistConfirmModal}
						cancelCallback={cancelCallbackRemoveCopyPlaylist}
						description={'Er du sikker pø, at du vil fjerne denne spilleliste?'}
					/>
					// </View>
				)}


				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}

				{showImportThirdPartyModal && (
					<ImportFromThirdPartyModal
						cancelCallback={cancelCallbackThirdParty}
					/>
				)}

				{showRequestedSongsModal && (
					<RequestedSongs
						cancelCallback={cancelCallbackRequestedSongs}
					/>
				)}
			</ScrollView>
		</View>
	)
}
